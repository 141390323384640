import * as React from "react";

import Layout from "../components/layouts/Layout";

import SectionLayoutGrid from "../components/styled-components/wrappers/SectionLayoutGrid";
import H2 from "../components/styled-components/typography/H2";
import Col from "../components/styled-components/wrappers/Col";
import P from "../components/styled-components/typography/P";
import LinkButton from "../components/styled-components/button/LinkButton";


const NotFoundPage = () => {
    return (
        <Layout title={"Hiba - 404"}>
            <SectionLayoutGrid id="404">
                <Col full grid center>
                    <H2>
                        A kért oldal nem található.
                    </H2>
                    <P>
                        Ha vissza szeretne térni a kezdőlapra, kérjük kattintson a 'Vissza' gombra.
                    </P>
                </Col>
                <Col full center>
                    <LinkButton to={"/"}>
                        Vissza
                    </LinkButton>
                </Col>
            </SectionLayoutGrid>
        </Layout>
    )
}

export default NotFoundPage
